<template>
  <StoreServiceScoreDetail :detailData="detailData" />
</template>

<script>
import StoreServiceScoreDetail from '@/components/teacher/examCenter/examData/StoreServiceScoreDetail.vue'
export default {
  name: "StoreCustomerDetail",
  components:{
    StoreServiceScoreDetail
  },
  data(){
    return {
      detailData:{}
    }
  },
  mounted() {
    this.detailData = JSON.parse(localStorage.getItem('issueItem'))
  },

}
</script>

<style scoped lang="scss">

</style>